import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 512 512">
    <path
      fill="#e58a7b"
      d="M340.773 162.118v-43.063h6.01a18.526 18.526 0 0 1 18.527 18.526 24.537 24.537 0 0 1-24.537 24.537ZM171.228 162.119a24.537 24.537 0 0 1-24.537-24.537 18.526 18.526 0 0 1 18.526-18.527h6.011v43.064Z"
    />
    <path
      fill="#f9f7f8"
      d="M461.56 501.999V375.317a60 60 0 0 0-38.552-56.035l-101.044-38.676a19.308 19.308 0 0 1-12.406-18.032v-25.682H202.442v25.682a19.308 19.308 0 0 1-12.406 18.032L88.992 319.282a60 60 0 0 0-38.552 56.035V502a10 10 0 0 0 10 10h391.12a10 10 0 0 0 10-10Z"
    />
    <path
      fill="#f9beac"
      d="M321.964 280.606a19.308 19.308 0 0 1-12.406-18.032v-25.682H202.442v25.682a19.308 19.308 0 0 1-12.406 18.032l65.953 19.11Z"
    />
    <path
      fill="#343c4a"
      d="m421.223 318.599-58.782-22.5-5.873 16.124a6 6 0 0 0 3.727 7.622l48.322 16.582a6 6 0 0 0 7.622-3.728zm-317.84 17.828 48.322-16.582a6 6 0 0 0 3.727-7.622l-5.497-16.268-59.038 22.598 4.864 14.146a6 6 0 0 0 7.622 3.728z"
    />
    <path
      fill="#eaeaea"
      d="M383.284 511.999a3.5 3.5 0 0 1-3.5-3.5v-73.174a3.5 3.5 0 0 1 7 0V508.5a3.5 3.5 0 0 1-3.5 3.5zm-254.568 0a3.5 3.5 0 0 1-3.5-3.5v-73.174a3.5 3.5 0 1 1 7 0V508.5a3.5 3.5 0 0 1-3.5 3.5z"
    />
    <path
      fill="#343c4a"
      d="m283.06 483.579-22.028 22.785a7 7 0 0 1-10.065.001L228.93 483.58a7 7 0 0 1-1.888-5.92l19.957-131.017h17.99l19.958 131.017a7 7 0 0 1-1.887 5.92Z"
    />
    <path
      fill="#243047"
      d="m296.24 321.533-33.059 33.05a10.166 10.166 0 0 1-14.373 0l-33.06-33.05L256 299.716Z"
    />
    <path
      fill="#eaeaea"
      d="m256 299.716-30.93 27.01c-8.845 7.723-23.356 4.822-27.93-5.584l-16.096-36.614 20.946-17.82Z"
    />
    <path
      fill="#eaeaea"
      d="m255.989 299.716 30.93 27.01c8.845 7.723 23.356 4.822 27.93-5.584l16.096-36.614L310.01 266.7Z"
    />
    <path
      fill="#e58a7b"
      d="M266.435 286.444a51.69 51.69 0 0 0 31.493-10.702l11.207-8.61c.281-.216.554-.44.832-.659a19.292 19.292 0 0 1-.41-3.899v-25.682H202.443v16.654a98.856 98.856 0 0 0 14.423 13.585l11.207 8.611a51.69 51.69 0 0 0 31.493 10.702Z"
    />
    <path
      fill="#f9beac"
      d="M340.773 118.242v49.477a98.886 98.886 0 0 1-38.637 78.412l-11.208 8.611a51.69 51.69 0 0 1-31.493 10.702h-6.87a51.69 51.69 0 0 1-31.493-10.702l-11.208-8.61a98.886 98.886 0 0 1-38.637-78.413v-49.477c29.572 6.15 51.258 2.758 65.863-1.705 20.355-6.22 27.78-14.768 45.323-16.878 23.439-2.817 44.193 8.355 58.36 18.583Z"
    />
    <path
      fill="#e58a7b"
      d="M256.014 239.027c-.316 0-.63-.004-.947-.012a36.715 36.715 0 0 1-13.053-2.752 3.5 3.5 0 1 1 2.658-6.475 29.732 29.732 0 0 0 10.573 2.229 30.117 30.117 0 0 0 12.085-2.23 3.5 3.5 0 1 1 2.654 6.477 36.645 36.645 0 0 1-13.97 2.763zm12.472-53.696v5.345A12.486 12.486 0 0 1 256 203.162a12.486 12.486 0 0 1-12.486-12.486v-5.345a3 3 0 0 1 3-3h18.972a3 3 0 0 1 3 3z"
    />
    <path
      fill="#343c4a"
      d="M293.782 172.026a3.5 3.5 0 0 1-3.5-3.5v-8.054a3.5 3.5 0 0 1 7 0v8.054a3.5 3.5 0 0 1-3.5 3.5zm-75.564 0a3.5 3.5 0 0 1-3.5-3.5v-8.054a3.5 3.5 0 0 1 7 0v8.054a3.5 3.5 0 0 1-3.5 3.5z"
    />
    <path
      fill="#f9f7f8"
      d="M350.77 107.505H161.23A27.23 27.23 0 0 1 134 80.275a27.23 27.23 0 0 1 13.678-23.618l78.094-44.808a60.74 60.74 0 0 1 60.456 0l78.094 44.808A27.23 27.23 0 0 1 378 80.275a27.23 27.23 0 0 1-27.23 27.23Z"
    />
    <path
      fill="#343c4a"
      d="M294.37 146.697h-80.394a48.092 48.092 0 0 1-39.132-20.136l-13.614-19.056h185.885l-13.614 19.056a48.092 48.092 0 0 1-39.132 20.136Z"
    />
    <path
      fill="#ffbc5b"
      d="M250.5 61.687V41.623a2.373 2.373 0 0 0-1.106-2.007l-6.38-4.028a2.373 2.373 0 0 1 .226-4.14l11.678-5.695a6.662 6.662 0 0 1 9.582 5.987v29.947zm-56-8.968h53v14h-39a14 14 0 0 1-14-14z"
    />
    <path fill="#e88f37" d="M208.5 66.72h53v14h-39a14 14 0 0 1-14-14Z" />
    <path fill="#ffbc5b" d="M303.5 66.72h-39v-14h53a14 14 0 0 1-14 14Z" />
    <path fill="#e88f37" d="M289.5 80.72h-39v-14h53a14 14 0 0 1-14 14Z" />
    <circle cx="256" cy="67.719" r="21.208" fill="#343c4a" />
  </svg>
);

export default IconLogo;
