// import React from 'react';

// const IconLoader = () => (
//   <svg
//     id="logo"
//     version="1.0"
//     xmlns="http://www.w3.org/2000/svg"
//     width="437.000000pt"
//     height="257.000000pt"
//     viewBox="0 0 437.000000 257.000000"
//     preserveAspectRatio="xMidYMid meet"
//   >
//     <metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
//     <g transform="translate(0.000000,257.000000) scale(0.100000,-0.100000)">
//       <path
//         stroke="black"
//         strokeWidth="10"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M4299 2556 c-2 -2 -58 -9 -124 -15 -168 -16 -520 -94 -694 -152 -166
// -56 -388 -162 -518 -248 -179 -117 -401 -328 -538 -510 -50 -66 -156 -235
// -264 -421 -6 -11 -20 9 -55 79 -32 63 -51 90 -58 83 -5 -5 -12 -46 -15 -91 -5
// -75 -4 -86 25 -149 17 -37 45 -102 62 -145 18 -42 35 -77 40 -77 4 0 36 65 70
// 145 82 190 158 325 271 481 160 220 374 417 594 546 206 121 473 225 671 262
// 65 12 225 30 278 31 20 0 26 -61 15 -140 -13 -98 -86 -300 -140 -389 -21 -36
// -39 -71 -39 -78 0 -19 62 -78 82 -78 16 0 124 137 185 235 53 85 129 240 161
// 329 34 92 69 265 59 290 -6 14 -56 23 -68 12z"
//       />
//       <path
//         stroke="black"
//         strokeWidth="10"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M40 2531 c-14 -4 -26 -14 -28 -24 -11 -58 88 -393 170 -577 59 -132
// 187 -309 317 -439 172 -172 519 -370 721 -410 l55 -11 -57 -61 c-62 -66 -113
// -156 -149 -264 -21 -62 -24 -87 -24 -230 1 -189 12 -241 86 -392 39 -81 54
// -103 70 -103 34 0 250 103 340 162 122 79 236 197 286 295 70 136 78 169 78
// 338 0 168 -15 235 -82 370 -103 204 -314 400 -568 525 -274 135 -502 171 -698
// 110 -76 -24 -81 -34 -42 -93 29 -42 32 -43 81 -22 101 42 229 38 389 -12 119
// -38 212 -85 359 -183 204 -136 334 -287 407 -473 29 -73 33 -95 37 -205 4
// -108 1 -132 -19 -194 -29 -92 -81 -168 -173 -256 -74 -70 -244 -180 -314 -203
// -31 -10 -37 -9 -49 7 -47 64 -79 238 -70 386 4 82 8 96 62 204 74 152 125 205
// 258 268 103 49 137 81 137 129 l0 30 -47 -8 c-27 -4 -70 -11 -98 -15 -154 -24
// -473 85 -660 225 -80 60 -226 205 -287 285 -117 153 -194 370 -206 583 l-7
// 117 32 0 c105 0 384 -85 558 -169 365 -177 602 -372 921 -758 37 -46 74 -83
// 80 -83 7 0 16 20 20 45 3 25 8 44 11 42 2 -3 -3 -58 -13 -123 -25 -180 -15
// -443 24 -629 50 -236 152 -512 186 -503 19 6 102 186 140 303 54 165 98 382
// 116 569 11 111 3 239 -14 233 -6 -2 -27 -35 -48 -73 -31 -58 -38 -85 -48 -170
// -12 -116 -46 -249 -91 -365 l-32 -80 -24 58 c-41 99 -101 325 -113 425 -8 70
// -8 129 0 224 13 153 26 219 80 409 38 135 40 146 26 178 -17 42 -30 51 -45 34
// -18 -22 -99 -276 -120 -377 -11 -52 -20 -85 -20 -73 -1 27 -177 228 -317 363
// -283 272 -656 488 -1020 590 -178 50 -495 90 -564 71z"
//       />
//       <path
//         stroke="black"
//         strokeWidth="10"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M1575 2528 c-28 -16 -35 -28 -35 -65 0 -38 29 -73 61 -73 32 0 59 19
// 59 40 0 11 5 20 10 20 33 0 169 -93 224 -153 121 -134 230 -315 280 -467 28
// -88 76 -296 76 -334 0 -42 28 -45 61 -5 52 62 53 76 24 185 -29 111 -85 257
// -123 324 -19 32 -21 41 -9 31 10 -8 19 -12 21 -10 2 2 31 45 64 94 86 127 180
// 232 254 282 l63 42 18 -24 c39 -52 112 -19 105 47 -3 29 -8 35 -45 47 -57 20
// -102 5 -200 -68 -137 -102 -319 -312 -308 -356 5 -17 3 -16 -12 5 -125 174
// -249 309 -343 373 -93 65 -197 92 -245 65z"
//       />
//       <path
//         stroke="black"
//         strokeWidth="10"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M3405 1859 c-213 -31 -511 -191 -710 -381 -108 -103 -164 -182 -235
// -330 -66 -139 -81 -199 -87 -353 -5 -116 -3 -137 17 -195 75 -221 172 -346
// 365 -467 132 -83 392 -158 422 -121 6 7 20 67 32 133 36 207 23 455 -33 617
// -31 89 -109 219 -158 263 -39 36 -39 45 0 45 42 0 260 78 367 131 55 27 140
// 78 189 113 116 84 276 217 276 231 0 17 -60 85 -76 85 -8 0 -61 -40 -119 -89
// -167 -144 -339 -248 -523 -318 -136 -51 -312 -68 -420 -39 -57 14 -67 9 -58
// -29 11 -42 55 -83 146 -135 57 -33 106 -70 135 -103 59 -67 141 -232 160 -322
// 18 -84 19 -246 4 -366 l-11 -88 -80 29 c-391 143 -580 448 -489 790 31 118 70
// 195 157 310 149 198 348 341 584 421 83 28 112 32 230 36 121 5 141 3 193 -17
// 78 -30 149 -88 178 -147 19 -38 24 -64 24 -128 0 -71 -4 -89 -33 -147 -43 -88
// -130 -168 -249 -231 -89 -46 -135 -59 -259 -71 l-60 -7 4 34 c7 65 -64 92
// -113 42 -54 -54 -16 -124 79 -146 33 -7 99 -10 177 -6 104 4 138 9 209 34 119
// 42 141 55 218 128 118 111 157 210 150 389 -5 137 -25 185 -111 269 -124 120
// -287 165 -492 136z"
//       />
//       <path
//         stroke="black"
//         strokeWidth="10"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M312 1653 c-19 -7 -58 -90 -71 -151 -30 -143 17 -296 133 -428 43
// -50 182 -123 276 -145 113 -27 307 -9 372 34 67 46 56 127 -17 127 -38 0 -52
// -14 -57 -55 -3 -29 -5 -30 -62 -33 -72 -4 -216 35 -291 78 -166 95 -278 325
// -216 444 l20 39 -30 43 c-33 49 -39 53 -57 47z"
//       />
//     </g>
//   </svg>
// );

// export default IconLoader;

import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="J" transform="translate(11.000000, 5.000000)">
        <path
          d="M40.81,24.08q.78-.73,1.5-1.47A4,4,0,0,0,47,16.4c3.3-6.52-.71-11.79-15-7C28.06-3.09,20-3.16,16,9.31-.43,3.88-5.42,11.94,7.48,24.09c-.52.49-1,1-1.48,1.46-4.1-2.38-8.11,3.1-4.77,6.32C-.32,35,.11,37,.78,38.08c2.49,4.16,10.7,2.29,15.31.77,4,12.24,11.9,12.2,15.85-.09C48.73,44.42,53.78,36.29,40.81,24.08ZM44,21a2,2,0,1,1,2-2A2,2,0,0,1,44,21Zm1.35-5.75a4,4,0,0,0-4.59,6.08c-.45.47-.93.93-1.43,1.4-8.66-7.55-4-.52-6.79-11.39C40.06,8.79,48.8,8.1,45.35,15.25Zm-21.2,18a73,73,0,0,1-8-4.77,54.64,54.64,0,0,1,0-8.77,73.7,73.7,0,0,1,8-4.76,72.77,72.77,0,0,1,7.66,4.57,56.13,56.13,0,0,1,0,9.17A74.45,74.45,0,0,1,24.15,33.24Zm7.37-2a42.36,42.36,0,0,1-.93,4.88c-1.38-.53-2.8-1.14-4.24-1.82C28.42,33.21,29.78,32.4,31.52,31.27Zm-9.58,3.06c-1.53.72-3,1.37-4.51,1.92a43.66,43.66,0,0,1-1-5.19C18.45,32.36,19.92,33.24,21.94,34.33ZM14.08,27c-1.28-.95-2.49-1.92-3.62-2.89,1.13-1,2.33-1.94,3.61-2.88C14,23.18,14,25,14.08,27Zm2.34-9.84a40.31,40.31,0,0,1,1-5.23c1.48.56,3,1.21,4.54,1.94C19.76,15,18.24,16,16.42,17.14Zm10-3.29q2.18-1,4.26-1.82a42.2,42.2,0,0,1,.92,4.89C29.66,15.7,28.13,14.81,26.37,13.85ZM33.92,21c1.39,1,2.7,2.05,3.9,3.1-1.21,1.05-2.52,2.1-3.91,3.12A56.15,56.15,0,0,0,33.92,21ZM30.09,10.09c-1.92.73-3.92,1.6-5.94,2.61A66.92,66.92,0,0,0,17.94,10C21.36-.82,26.81-.42,30.09,10.09Zm-14.6,1.14C12.59,22.52,17.28,15.48,9,22.74-2.55,12,1.16,6.48,15.49,11.23ZM4,27a2,2,0,1,1-2,2A2,2,0,0,1,4,27ZM3,32.85A4,4,0,0,0,7.42,27c.49-.51,1-1,1.54-1.52,8.36,7.29,3.62.22,6.57,11.48C8,39.42-.51,40,3,32.85Zm15,5.33a65.72,65.72,0,0,0,6.16-2.7c2,1,4,1.87,5.89,2.6C26.75,48.43,21.37,48.7,18,38.18Zm14.5-1.35c2.83-10.89-1.86-3.83,6.83-11.4C50.73,36.1,47.46,41.91,32.49,36.83Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="65"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 10, 0
           L 10, 0
           L 0, 20
           L 20, 0
           L 0, 10
           L 0, 10 z"
      />
    </g>
  </svg>
);

export default IconLoader;
